/**
 * Created by guiyang on 2018-12-03.
 */
// import Api from './Api'
const PingBack = require('./PingBack')
const Detect = require('./Detect')
const Utils = require('./Utils')
module.exports = {
  browser: {
    qq: /qqbrowser|QQ|QQbrowser/.test(navigator.userAgent),
    weixin: /MicroMessenger/i.test(navigator.userAgent),
    uc: /ucbrowser|ucweb/i.test(navigator.userAgent),
    baidubox: /baiduboxapp/i.test(navigator.userAgent),
    baidubrowser: /baidubrowser/i.test(navigator.userAgent),
    weibo: /weibo/i.test(navigator.userAgent),
    ios_egt_9: /iPhone OS (\d+)/i.exec(navigator.userAgent) && /iPhone OS (\d+)/i.exec(navigator.userAgent)[1] >= 9,
    ios_egt_10: /iPhone OS (\d+)/i.exec(navigator.userAgent) && /iPhone OS (\d+)/i.exec(navigator.userAgent)[1] > 9,
    qiyinews: /QYNews/.test(navigator.userAgent) && !(/IqiyiApp\/pps/.test(navigator.userAgent)), //爱奇艺头条webview //兼容pps图文详情页打开后两个ua头都有的情况 9.8.5后修复
    pps: /IqiyiApp\/pps/.test(navigator.userAgent), //PPSwebview
    iqiyiAPP: /IqiyiApp\/iqiyi/.test(navigator.userAgent), //只用来判断是否为爱奇艺系列app内(含头条)
    alipay: /Alipay/i.test(navigator.userAgent),
    ...Detect.browser
  },
  os: Detect.os,
  mock: true && process.env.NODE_ENV == 'development',
  appInfo: {
    ppuid: '',
    authCookie: '',
    deviceId: '',
    appVersion: '',
    'user-agent': window.userAgent,
    client_ip: '',
    key: '',
    deviceType: '',
    /**
     * 风控侧平台定义
     0：其他 
     1：android(安卓端)
     2：ios 
     3：PCW(PC网页) 
     4：H5
     5: PCA
     6: 微信小程序
     7: android tv
     8: UWP
     */
    platform: '',
    dfp: '',
    appChannel: '',
    networkStatus: '',
    qyID: '',
    sign: '',
    timestamp: '',
    userIcon: '',
    userName: '',

    appIdNaDou: 'wx3c55020afc58a8fc',
    appIdHaoDUo: 'wx03071aa0b1ab1541',
    appIdPPS: 'wx3b61b7eb6946daf5',
  },
  pageInfo: {
    title: '',
    pingBack: {
      pageName: '',
      rpage: '',
      block: '',
      rseat: ''
    },
    shareInfo: {
      shareType: 2,
      shareTitle: '爱奇艺随刻版',
      shareContent: '爱奇艺随刻版',
      shareUrl: '',
      shareIcon: '',
      display: false
    },
  },
  pingBack: null,
  // baseApiUrl: process.env.NODE_ENV == 'production' ? 'https://toutiao.iqiyi.com' : 'http://beta1.toutiao.qiyi.domain',
  testBasePPSApiUrl: process.env.NODE_ENV == 'production' ? 'http://10.16.171.78:8085' : (process.env.NODE_ENV == 'qa' ? 'http://10.16.171.78:8085' : 'http://localhost:8080'),
  testBaseApiUrl: process.env.NODE_ENV == 'production' ? 'http://10.153.138.2:8085' : 'http://10.153.138.2:8085',
  baseApiUrl: process.env.NODE_ENV == 'production' ? (window.location.protocol + '//toutiao.iqiyi.com') : 'http://10.16.171.78:8085',
  baseImageUrl: '//pic0.iqiyipic.com',
  baseHttpWebUrl: process.env.NODE_ENV == 'production' ? (window.location.protocol + '//static.iqiyi.com/nadou') : (process.env.NODE_ENV == 'qa' ? 'http://nadouh5.iqiyi.com/nadou' : 'http://sk.iqiyi.com/:8080'),
  baseWebUrl: process.env.NODE_ENV == 'production' ? (window.location.protocol + '//static.iqiyi.com/nadou') : (process.env.NODE_ENV == 'qa' ? 'http://nadouh5.iqiyi.com/nadou' : 'http://sk.iqiyi.com:8080'),
  //营销域名
  baseMarketWebUrl: process.env.NODE_ENV == 'production' ? ('') : (process.env.NODE_ENV == 'qa' ? 'http://nadouh5.iqiyi.com/nadou' : 'http://localhost:8080'),
  // _onCreate(onSuccess) {
  //   let self = this;
  //   self.pingBack = PingBack.getInstance();
  //   self.pageInfo && self.pageInfo.pingBack && self.pageInfo.pingBack.pageName && self.pingBack.sendDevPingBack(self.pageInfo.pingBack.pageName);
  //   self.pageInfo && self.pageInfo.pingBack && self.pageInfo.pingBack.rpage && self.pingBack.sendLoadPingBack(self.pageInfo.pingBack.rpage);

  //   let s2 = Utils.getUrlParam('s2')
  //   let social_platform = Utils.getUrlParam('social_platform')
  //   if (s2) {
  //     //Grafana 统计页面来源
  //     s2 = s2.replace(/_/g, "");
  //     self.pageInfo && self.pageInfo.pingBack && self.pageInfo.pingBack.pageName && self.pingBack.sendDevPingBack(self.pageInfo.pingBack.pageName + s2);
  //   }
  //   if (social_platform) {
  //     self.pageInfo && self.pageInfo.pingBack && self.pageInfo.pingBack.pageName && self.pingBack.sendDevPingBack(self.pageInfo.pingBack.pageName + social_platform);
  //   }
  //   onSuccess && onSuccess();
  // },
  
  // init(onSuccess) {
  //   var self = this;
  //   if (self.browser.qiyinews) {
  //     if (typeof QYNewsJSBridge != "undefined" && QYNewsJSBridge) {
  //       QYNewsJSBridge.call('jsBridgeInit', '', function (res) {
  //         console.log('jsBridgeInit', res);
  //         self.appInfo.platform = res.data.platform;
  //         self.appInfo.appVersion = res.data.version;
  //         self.appInfo.deviceId = res.data.deviceId;
  //         self.appInfo.dfp = res.data.dfp;
  //         self.appInfo.authCookie = res.data.authCookie;
  //         self.appInfo.client_ip = res.data.client_ip;
  //         self.appInfo.ppuid = res.data.ppuid;
  //         self.appInfo.sign = res.data.sign;
  //         self.appInfo.userIcon = res.data.userIcon;
  //         self.appInfo.userName = res.data.userName;
  //         self.appInfo.qyID = res.data.qyID;
  //         self.appInfo.timestamp = res.data.timestamp;
  //         self.appInfo.networkStatus = res.data.networkStatus;
  //         self.appInfo.appChannel = res.data.appChannel;
  //         self.appInfo.deviceType = res.data.deviceType;
  //         if (self.pageInfo && self.pageInfo.shareInfo && self.pageInfo.shareInfo.display) {
  //           setTimeout(function () {
  //             QYNewsJSBridge.call('setMenu', {
  //               'type': 'share',
  //               'shareType': self.pageInfo.shareInfo.shareType,
  //               'display': self.pageInfo.shareInfo.display,
  //               'shareTitle': self.pageInfo.shareInfo.shareTitle,
  //               'shareContent': self.pageInfo.shareInfo.shareContent,
  //               'shareUrl': self.pageInfo.shareInfo.shareUrl,
  //               'shareIcon': self.pageInfo.shareInfo.shareIcon
  //             }, function () {
  //               console.log('分享右上角分享调起成功');
  //             });
  //           }, 50);
  //         }
  //         self._onCreate(onSuccess);
  //       });
  //     } else {
  //       console.log('未发现 QYNewsJSBridge');
  //       self._onCreate(onSuccess);
  //     }
  //   } else if (self.browser.iqiyiAPP || self.browser.pps) {
  //     iqiyi.ready(function (flag) {
  //       if (flag) {
  //         iqiyi.init(function (result) {
  //           console.log('init', result);
  //           if (result.result == 0) {
  //             Utils.showToast('页面加载失败请重新打开后重试');
  //           } else {
  //             if (self.os.android) {
  //               self.appInfo.platform = 'ANDROID_PPS';
  //             } else if (self.os.ios) {
  //               self.appInfo.platform = 'IOS_PPS';
  //             }
  //             // self.appInfo.platform = result.data.platform;
  //             self.appInfo.appVersion = result.data.version;
  //             // self.appInfo.deviceId = result.data.deviceId;
  //             self.appInfo.deviceId = result.data.qyID;
  //             self.appInfo.dfp = result.data.dfp;
  //             self.appInfo.authCookie = result.data.P00001;
  //             // self.appInfo.client_ip = result.data.client_ip;
  //             self.appInfo.ppuid = Utils.ppuid2Format(result.data.P00003);
  //             // self.appInfo.sign = result.data.sign;
  //             try {
  //               if (self.os.android) {
  //                 self.appInfo.userInfo = result.data.P00002;
  //               } else if (self.os.ios) {
  //                 self.appInfo.userInfo = JSON.parse(decodeURIComponent(result.data.P00002.toString()));
  //               }
  //             } catch (e) {
  //               console.log('获取用户信息失败');
  //             }
  //             // self.appInfo.userIcon = userInfo.icon;
  //             // self.appInfo.userName = userInfo.nickname;
  //             self.appInfo.qyID = result.data.qyID;
  //             self.appInfo.timestamp = new Date().getTime();
  //             self.appInfo.networkStatus = result.data.networkStatus;
  //             // self.appInfo.appChannel = result.data.appChannel;
  //             self.appInfo.deviceType = result.data.deviceType;
  //             self.appInfo.biqid = result.data.biqid;
  //           }
  //           self._onCreate(onSuccess);
  //         });
  //       } else {
  //         self._onCreate(onSuccess);
  //         Utils.showToast('页面加载失败请重新打开后重试');
  //       }
  //     });
  //   } else if (this.browser.weixin) {
  //     console.log('微信环境初始化 self.pageInfo.shareInfo', self.pageInfo.shareInfo)
  //     if (self.pageInfo && self.pageInfo.shareInfo && self.pageInfo.shareInfo.display) {
  //       /**
  //        * 好多中延用 PPS中已废弃
  //        * @private
  //        */
  //       self._weiXinInitShare();
  //     }
  //     self._onCreate(onSuccess);
  //   } else {
  //     self._onCreate(onSuccess);
  //   }
  // }
}